import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faClose, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';
import { Patron } from 'src/app/core/openapi';
import { Client } from 'src/app/shared/interfaces';
import { PatronData } from 'src/app/shared/interfaces/Manager.interface';

@Component({
  selector: 'app-client-list-dialog',
  templateUrl: './client-list-dialog.component.html',
  styleUrls: ['./client-list-dialog.component.scss'],
})
export class ClientListDialogComponent implements OnInit {
  public readonly faClose: IconDefinition = faClose;
  dataSource: Client[] | Patron;
  originalDataSource: Client[] | Patron;
  displayedColumns: string[] = ['name', 'enabled'];

  searchTerm: string;

  public checked = true;
  public unchecked = false;

  constructor(
    public dialogRef: MatDialogRef<ClientListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatronData,
  ) {}

  ngOnInit(): void {
    if (this.data?.patrons) {
      this.dataSource = this.data.patrons;
      this.originalDataSource = Array.isArray(this.dataSource) ? [...this.dataSource] : [];
    }
  }

  clickIfClientIsAllowed(patron): boolean {
    const allowedClients = get(this.data, 'allowedClients', []);
    return allowedClients.includes(patron.id);
  }

  addEnabledClient(patron) {
    const allowedClients = get(this.data, 'allowedClients', []);
    const index = allowedClients.indexOf(patron.id);

    if (index > -1) {
      allowedClients.splice(index, 1);
    } else {
      allowedClients.push(patron.id);
    }
  }

  public close() {
    this.dialogRef.close(false);
  }

  saveClientList() {
    const allowedClients = get(this.data, 'allowedClients', []);
    this.dialogRef.close(allowedClients);
  }

  search() {
    this.dataSource = this.handleClientSearch(this.originalDataSource, this.searchTerm);
  }

  public handleClientSearch(items: Client[] | Patron, searchTerm: string) {
    if (!items) return [];
    if (!searchTerm) return items;

    searchTerm = searchTerm.toLowerCase();

    if (Array.isArray(items)) {
      return items.filter((it) => {
        return it.givenName ? it.givenName.toLowerCase().includes(searchTerm) : false;
      });
    }
    return [];
  }
}
