<body>
  <div class="close-icon">
    <fa-icon
      [icon]="faClose"
      (click)="close()"
      class="backIcon"
      matTooltip="Close modal"
    >
    </fa-icon>
  </div>
  <div class="container">
    <input
      type="text"
      class="filter-input"
      placeholder="Search..."
      [(ngModel)]="searchTerm"
      (input)="search()"
    />
    <div id="clientList">
      <div
        class="client-row"
        *ngFor="let client of dataSource"
      >
        <div class="client-name">{{ client?.givenName }}</div>
        <div class="checkbox-container">
          <label class="custom-checkbox">
            <input
              type="checkbox"
              [checked]="clickIfClientIsAllowed(client)"
              (change)="addEnabledClient(client)"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>

    <div
      id="noDataMessage"
      class="no-data"
      style="display: none"
    >
      No data matching the filter
    </div>

    <button
      class="save-button"
      (click)="saveClientList()"
    >
      Save
    </button>
  </div>
</body>
